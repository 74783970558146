import { render, staticRenderFns } from "./PreRequisitos.vue?vue&type=template&id=ccb23bb2&"
import script from "./PreRequisitos.vue?vue&type=script&lang=js&"
export * from "./PreRequisitos.vue?vue&type=script&lang=js&"
import style0 from "./PreRequisitos.vue?vue&type=style&index=0&id=ccb23bb2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports