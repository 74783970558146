<template>

    

    <div style="padding:20px;">


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >Pré-Requisitos Sistemas</h1>
                </div>

            </v-card-text>  
        </v-card>    

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>          

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h2>Ficha Digital</h2>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Hits</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe On Board da APP Sistemas as Credenciais de Integração API Hits
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>PropertyId</b>: f1f976af-xxxx-0000-3764-xxxxxxxxx<br>
                                    <b>Scopes</b>: "InternetMgr", "PtOfSale", "WebCheckIn" (<b>Recomenda-se todos os 3 scopes para garantir integração completa</b>)<br>
                                    <b>Tenant</b>: nometenanthotel<br>
                                    <b>Property Code</b>: 1
                                </p>
                            </div>
                        </div>
                        <hr style="margin-top:50px;margin-bottom:50px;">

                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Totvs</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe ao Cliente os dados de conexão para o Banco de dados
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>Tipo do Banco de Dados</b>: Oracle ou SQL Server<br>
                                    <b>Endereço IP do servidor de Banco de Dados</b>: 10.000.000.2<br>
                                    <b>Porta do servidor de Banco de dados</b>: 1521<br>
                                    <b>Usuario de acesso</b>: cmsol<br>
                                    <b>Nome do Banco</b>: cm
                                </p>
                            </div>
                        </div>
                        <hr style="margin-top:50px;margin-bottom:50px;">
          
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>ADMH</h3>
                                <p style="padding:10px;">
                                   O ficha digital do ADMH se comporta de maneira inversa, o ADMH que envia dados para o ficha digital e tbm coleta dados do ficha digital
                                </p>
                                <p style="padding:10px;">
                                   A economy precisa enviar para a Equipe ADMH de suporte os seguintes dados para configuração:
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>clientkey</b>: c0xxxx7ec4cxxxxxxx4baxxxxxx42 (<b>Cada cliente tem o seu</b>)<br>
                                    <b>partnerkey</b>: f870edddc10fa5e72f885b7d52b119529c885ac0 (<b>Sempre será o mesmo</b>)<br>
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
                
            <br><br>
            </v-card-text>
               
        </v-card>


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>          

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h2>Cardapio Digital</h2>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Hits</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe On Board da APP Sistemas as Credenciais de Integração API Hits
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>PropertyId</b>: f1f976af-xxxx-0000-3764-xxxxxxxxx<br>
                                    <b>Scopes</b>: "InternetMgr", "PtOfSale", "WebCheckIn" (<b>Recomenda-se todos os 3 scopes para garantir integração completa</b>)<br>
                                    <b>Tenant</b>: nometenanthotel<br>
                                    <b>Property Code</b>: 1
                                </p>
                            </div>
                        </div>
                        <br><br>
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Totvs</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe ao Cliente os dados de conexão para o Banco de dados
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Acesso Direto entre servidores</b>: Se faz necessário a liberação de acesso direto do servidor de banco para o nosso servidor remoto<br>
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>Tipo do Banco de Dados</b>: Oracle ou SQL Server<br>
                                    <b>Endereço IP do servidor de Banco de Dados</b>: 10.000.000.2<br>
                                    <b>Porta do servidor de Banco de dados</b>: 1521<br>
                                    <b>Usuario de acesso</b>: cmsol<br>
                                    <b>Nome do Banco</b>: cm
                                </p>
                            </div>
                        </div>

                        <br><br>
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>ADMH - Ainda em integração</h3>
                                <!-- <p style="padding:10px;">
                                   O ficha digital do ADMH se comporta de maneira inversa, o ADMH que envia dados para o ficha digital e tbm coleta dados do ficha digital
                                </p>
                                <p style="padding:10px;">
                                   A economy precisa enviar para a Equipe ADMH de suporte os seguintes dados para configuração:
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>clientkey</b>: c0xxxx7ec4cxxxxxxx4baxxxxxx42 (<b>Cada cliente tem o seu</b>)<br>
                                    <b>partnerkey</b>: f870edddc10fa5e72f885b7d52b119529c885ac0 (<b>Sempre será o mesmo</b>)<br>
                                </p> -->
                            </div>
                        </div>


                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">

            </v-card-text>
               
        </v-card>


        
        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>          

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h2>Check in Pay - Requisitos Comuns para Todas as Integrações</h2>
                    <div style="margin-top:20px;padding-left:20px;">

                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Para todos os Casos</h3>
                                <p style="padding:10px;">
                                   Estes são os requisitos comuns para os Check in Pay em todas as integraçãos.<br>
                                   Além dos especificos, é necessário Solicitar também os requisitos espeficios por tipo de integração.
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Gateway de Pagamento</b>: Cielo, Getnet, Rede, Pagar.me/Stone<br>
                                    <b>Chaves de Integração</b>: Cada Gateway geralmente possui duas chaves de integração que precisam ser fornecidas para que possamos realizar a integração.
                                </p>
                            </div>
                        </div>
                        <br><br>
                    </div>
                        
                        <h2>Check in Pay  - Requisitos Especificos por Integração</h2>
                        <div style="margin-top:20px;padding-left:20px;">
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Hits</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe On Board da APP Sistemas as Credenciais de Integração API Hits
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>PropertyId</b>: f1f976af-xxxx-0000-3764-xxxxxxxxx<br>
                                    <b>Scopes</b>: "InternetMgr", "PtOfSale", "WebCheckIn" (<b>Recomenda-se todos os 3 scopes para garantir integração completa</b>)<br>
                                    <b>Tenant</b>: nometenanthotel<br>
                                    <b>Property Code</b>: 1
                                </p>
                            </div>
                        </div>
                        <br><br>
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Totvs</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe ao Cliente os dados de conexão para o Banco de dados
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Acesso Direto entre servidores</b>: Se faz necessário a liberação de acesso direto do servidor de banco para o nosso servidor remoto<br>
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>Tipo do Banco de Dados</b>: Oracle ou SQL Server<br>
                                    <b>Endereço IP do servidor de Banco de Dados</b>: 10.000.000.2<br>
                                    <b>Porta do servidor de Banco de dados</b>: 1521<br>
                                    <b>Usuario de acesso</b>: cmsol<br>
                                    <b>Nome do Banco</b>: cm
                                </p>
                            </div>
                        </div>

                        <br><br>
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>ADMH</h3>
                                <p style="padding:10px;">
                                   No caso do check in pay, precisamos das mesmos requisitos do ficha digital.
                                   Também precisamos de requisitos especiais para integração do robô check in pay.
                                </p>
                                <p style="padding:10px;">
                                   A economy precisa enviar para a Equipe ADMH de suporte os seguintes dados para configuração:<br>
                                   <b>clientkey</b>: c0xxxx7ec4cxxxxxxx4baxxxxxx42 (<b>Cada cliente tem o seu</b>)<br>
                                    <b>partnerkey</b>: f870edddc10fa5e72f885b7d52b119529c885ac0 (<b>Sempre será o mesmo</b>)
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    A economy precisa Solicitar ao Cliente os seguites dados para Integrar o Robo Check in Pay com a API ADMH:<br>
                                    <b>url do webservice</b>: http://localhost:xxxx<br>
                                    <b>chave</b>: economy<br>
                                    <b>usuário</b>: 1<br>
                                    <b>senha </b>: XXXX<br>
                                    <b>integrador </b>: economy<br>
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">

            </v-card-text>
               
        </v-card>

  
        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>          

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h2>Check Out - Requisitos Comuns para Todas as Integrações</h2>
                    <div style="margin-top:20px;padding-left:20px;">

                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Para todos os Casos</h3>
                                <p style="padding:10px;">
                                   Estes são os requisitos comuns para os Check Out em todas as integraçãos.<br>
                                   Além dos especificos, é necessário Solicitar também os requisitos espeficios por tipo de integração.
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Gateway de Pagamento</b>: Cielo, Getnet, Rede, Pagar.me/Stone<br>
                                    <b>Chaves de Integração</b>: Cada Gateway geralmente possui duas chaves de integração que precisam ser fornecidas para que possamos realizar a integração.
                                </p>
                            </div>
                        </div>
                        <br><br>
                    
                    </div>
                    <h2>Check in Pay  - Requisitos Especificos por Integração</h2>
                     <div style="margin-top:20px;padding-left:20px;">
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Hits</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe On Board da APP Sistemas as Credenciais de Integração API Hits
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>PropertyId</b>: f1f976af-xxxx-0000-3764-xxxxxxxxx<br>
                                    <b>Scopes</b>: "InternetMgr", "PtOfSale", "WebCheckIn" (<b>Recomenda-se todos os 3 scopes para garantir integração completa</b>)<br>
                                    <b>Tenant</b>: nometenanthotel<br>
                                    <b>Property Code</b>: 1
                                </p>
                            </div>
                        </div>
                        <br><br>
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Totvs</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe ao Cliente os dados de conexão para o Banco de dados
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Acesso Direto entre servidores</b>: Se faz necessário a liberação de acesso direto do servidor de banco para o nosso servidor remoto<br>
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>Tipo do Banco de Dados</b>: Oracle ou SQL Server<br>
                                    <b>Endereço IP do servidor de Banco de Dados</b>: 10.000.000.2<br>
                                    <b>Porta do servidor de Banco de dados</b>: 1521<br>
                                    <b>Usuario de acesso</b>: cmsol<br>
                                    <b>Nome do Banco</b>: cm
                                </p>
                            </div>
                        </div>

                        <br><br>
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>ADMH</h3>
                                <p style="padding:10px;">
                                   No caso do Check Out, precisamos das mesmos requisitos do ficha digital.
                                   Também precisamos de requisitos especiais para integração do robô Check Out.
                                </p>
                                <p style="padding:10px;">
                                   A economy precisa enviar para a Equipe ADMH de suporte os seguintes dados para configuração:<br>
                                   <b>clientkey</b>: c0xxxx7ec4cxxxxxxx4baxxxxxx42 (<b>Cada cliente tem o seu</b>)<br>
                                    <b>partnerkey</b>: f870edddc10fa5e72f885b7d52b119529c885ac0 (<b>Sempre será o mesmo</b>)
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    A economy precisa Solicitar ao Cliente os seguites dados para Integrar o Robo Check in Pay com a API ADMH:<br>
                                    <b>url do webservice</b>: http://localhost:xxxx<br>
                                    <b>chave</b>: economy<br>
                                    <b>usuário</b>: 1<br>
                                    <b>senha </b>: XXXX<br>
                                    <b>integrador </b>: economy<br>
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">

            </v-card-text>
               
        </v-card>


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>          

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h2>FD Guest</h2>
                    <div style="margin-top:20px;padding-left:20px;">
                        
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Hits</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe On Board da APP Sistemas as Credenciais de Integração API Hits
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>PropertyId</b>: f1f976af-xxxx-0000-3764-xxxxxxxxx<br>
                                    <b>Scopes</b>: "InternetMgr", "PtOfSale", "WebCheckIn" (<b>Recomenda-se todos os 3 scopes para garantir integração completa</b>)<br>
                                    <b>Tenant</b>: nometenanthotel<br>
                                    <b>Property Code</b>: 1
                                </p>
                            </div>
                        </div>
                        <hr style="margin-top:50px;margin-bottom:50px;">

                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Totvs</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe ao Cliente os dados de conexão para o Banco de dados
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>Tipo do Banco de Dados</b>: Oracle ou SQL Server<br>
                                    <b>Endereço IP do servidor de Banco de Dados</b>: 10.000.000.2<br>
                                    <b>Porta do servidor de Banco de dados</b>: 1521<br>
                                    <b>Usuario de acesso</b>: cmsol<br>
                                    <b>Nome do Banco</b>: cm
                                </p>
                            </div>
                        </div>
                        <hr style="margin-top:50px;margin-bottom:50px;">
          
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>ADMH</h3>
                                <p style="padding:10px;">
                                   O ficha digital do ADMH se comporta de maneira inversa, o ADMH que envia dados para o ficha digital e tbm coleta dados do ficha digital
                                </p>
                                <p style="padding:10px;">
                                   A economy precisa enviar para a Equipe ADMH de suporte os seguintes dados para configuração:
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>clientkey</b>: c0xxxx7ec4cxxxxxxx4baxxxxxx42 (<b>Cada cliente tem o seu</b>)<br>
                                    <b>partnerkey</b>: f870edddc10fa5e72f885b7d52b119529c885ac0 (<b>Sempre será o mesmo</b>)<br>
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
                
            <br><br>
            </v-card-text>
               
        </v-card>



        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>          

                <div style="margin-top:20px;margin-left:30px;font-size:18px;">
                    <h2>Espot - Requisitos Comuns para Todas as Integrações</h2>
                    <div style="margin-top:20px;padding-left:20px;">
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Para casos em que o hotel Já Possua mikrotik com licença nivel 5 ou 6</h3>
                                <p style="padding:10px;">
                                   bla bla
                                </p>
                                <br>
                                <h3>Para casos em que o hotel não possua mikrotik com licença nivel 5 ou 6</h3>
                                <p style="padding:10px;">
                                   bla bla
                                </p>
                                <br>
                            </div>
                        </div>
                        <br><br>
                    </div>

                    <h2>Espot - Requisitos Especificos por tipo de Integração</h2>                    
                    <div style="margin-top:20px;padding-left:20px;">
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Hits</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe On Board da APP Sistemas as Credenciais de Integração API Hits
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>PropertyId</b>: f1f976af-xxxx-0000-3764-xxxxxxxxx<br>
                                    <b>Scopes</b>: "InternetMgr", "PtOfSale", "WebCheckIn" (<b>Recomenda-se todos os 3 scopes para garantir integração completa</b>)<br>
                                    <b>Tenant</b>: nometenanthotel<br>
                                    <b>Property Code</b>: 1
                                </p>
                            </div>
                        </div>
                        <br><br>
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>Totvs</h3>
                                <p style="padding:10px;">
                                   Solicitar a Equipe ao Cliente os dados de conexão para o Banco de dados
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>Acesso Direto entre servidores</b>: Se faz necessário a liberação de acesso direto do servidor de banco para o nosso servidor remoto<br>
                                    <b>Nome da Propriedade</b>: HOTEL NOME DO HOTEL<br>
                                    <b>Tipo do Banco de Dados</b>: Oracle ou SQL Server<br>
                                    <b>Endereço IP do servidor de Banco de Dados</b>: 10.000.000.2<br>
                                    <b>Porta do servidor de Banco de dados</b>: 1521<br>
                                    <b>Usuario de acesso</b>: cmsol<br>
                                    <b>Nome do Banco</b>: cm
                                </p>
                            </div>
                        </div>

                        <br><br>
                        <div style="margin-top:20px;">
                            <div style="padding-left:20px;">
                                <h3>ADMH</h3>
                                <p style="padding:10px;">
                                   O ficha digital do ADMH se comporta de maneira inversa, o ADMH que envia dados para o ficha digital e tbm coleta dados do ficha digital
                                </p>
                                <p style="padding:10px;">
                                   A economy precisa enviar para a Equipe ADMH de suporte os seguintes dados para configuração:
                                </p>
                                <br>
                                <p style="padding:10px;">
                                    <b>clientkey</b>: c0xxxx7ec4cxxxxxxx4baxxxxxx42 (<b>Cada cliente tem o seu</b>)<br>
                                    <b>partnerkey</b>: f870edddc10fa5e72f885b7d52b119529c885ac0 (<b>Sempre será o mesmo</b>)<br>
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
                <hr style="margin-top:50px;margin-bottom:50px;">

            </v-card-text>
               
        </v-card>



    </div>

    
</template>

<script>

export default {
    name:'Robos',
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>